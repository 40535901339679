import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "item-codes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#item-codes",
        "aria-label": "item codes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Item codes`}</h1>
    <p>{`Over 10,000 unique item codes are supported in Medipass across all funders. Item codes are sometimes called item numbers, paycodes or material numbers. Item code validity, description, pricing and referral requirements may vary depending on the date of service and servicing provider.  If you pass an invalid item code for a given funder, the Medipass UI will alert the user of error and in some cases suggest a related and valid item code.`}</p>
    <p>{`Medipass provides an item code lookup service via the `}<a parentName="p" {...{
        "href": "/sdk/sdk-reference/claim-items/"
      }}>{`Get Claim Item`}</a>{` method in the SDK:`}</p>
    <p>{`The SDK will return valid item codes and scheme description for a given business and funder.`}</p>
    <p>{`For example, to search for “professional attendance” items:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.claimItems.getBusinessClaimItems({
  page: 1,
  limit: 25,
  searchText: 'Professional attendance'
});
`}</code></pre>
    <p>{`Otherwise, item codes can be obtained as follows:`}</p>
    <h3 {...{
      "id": "comcare",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#comcare",
        "aria-label": "comcare permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comcare`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.comcare.gov.au/service-providers/medical-allied-health/treatment-rates"
      }}>{`https://www.comcare.gov.au/service-providers/medical-allied-health/treatment-rates`}</a>{` - Rates for medical and allied health treatment`}</p>
    <h3 {...{
      "id": "dva",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#dva",
        "aria-label": "dva permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DVA`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.dva.gov.au/providers/fees-and-payments/fees-schedules"
      }}>{`https://www.dva.gov.au/providers/fees-and-payments/fees-schedules`}</a></p>
    <h3 {...{
      "id": "hicaps--private-health-insurance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hicaps--private-health-insurance",
        "aria-label": "hicaps  private health insurance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HICAPS / Private Health Insurance`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.hicaps.com.au/forms/item-number-guides"
      }}>{`https://www.hicaps.com.au/forms/item-number-guides`}</a></p>
    <h3 {...{
      "id": "icare",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#icare",
        "aria-label": "icare permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`icare`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.sira.nsw.gov.au"
      }}>{`https://www.sira.nsw.gov.au`}</a>{` - search for fees and rates orders`}</p>
    <h3 {...{
      "id": "medicare",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#medicare",
        "aria-label": "medicare permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medicare`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.mbsonline.gov.au/internet/mbsonline/publishing.nsf/Content/Home"
      }}>{`http://www.mbsonline.gov.au/internet/mbsonline/publishing.nsf/Content/Home`}</a></p>
    <h3 {...{
      "id": "workcover-queensland",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#workcover-queensland",
        "aria-label": "workcover queensland permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Workcover Queensland`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.worksafe.qld.gov.au/service-providers/fees"
      }}>{`https://www.worksafe.qld.gov.au/service-providers/fees`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      